import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from './styles/global';
import Routes from './routes'

ReactDOM.render(
  <React.StrictMode>
    {/* <App/> */}
    <Routes/>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

